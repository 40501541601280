import { useRoute } from 'vue-router'

export function useUtils() {
    const route = useRoute();

    const isDeepEqual = (x: Object, y: Object) => {
        if (x === y) {
            return true;
        }
        else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
            if (Object.keys(x).length != Object.keys(y).length)
                return false;

            for (var prop in x) {
                if (y.hasOwnProperty(prop)) {
                    if (!isDeepEqual(x[prop], y[prop]))
                        return false;
                }
                else
                    return false;
            }

            return true;
        }
        else
            return false;
    }

    const removeDuplicatesFromArray = (dirtyArray: Array) => {
        const result = dirtyArray.filter((value, index, array) =>
            array.indexOf(value) === index
        );

        return result;
    }

    const getGroupPageNumber = () => {

        let result = parseInt(route.query.p) || 1;

        return result;
    }

    const gtmDataLayerPush = (object: Object) => {
        if (process.client && dataLayer) {
            dataLayer.push(object);
        }
    }

    const slugify = (text: String) => {
        if (!text) return '';

        // make lower case and trim
        var slug = text.toLowerCase().trim();

        // remove accents from charaters
        slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

        // replace invalid chars with spaces
        slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();

        // replace multiple spaces or hyphens with a single hyphen
        slug = slug.replace(/[\s-]+/g, '-');

        return slug;
    }

    const getWareImageUrl = (imageId: Number, wareName: String, suffix: String) => {
        const slug = useUtils().slugify(wareName);

        let fileName;


        if (typeof imageId === 'undefined' || imageId === null) {
            fileName = `000000_.jpg`;
        }
        else {
            fileName = `${slug}-${(imageId ?? '000000').toString().padStart(6, '0')}_${suffix}.jpg`;
        }

        const result = `${useShopZone().value.staticResourcesRoot}/wareImages/${fileName}`;

        return result;
    }

    const capitalize = (text: String) => {

        if (text == null || typeof text === 'undefined') {
            return '';
        }

        let result = text.charAt(0).toUpperCase() + text.slice(1);
        return result;
    }

    const scrollTo = (top: number, offset: number | undefined = 15) => {

        let headerOffset = 0;

        const headerSticky = document.querySelector('.header--sticky');

        if (headerSticky) {
            headerOffset = headerSticky.offsetHeight;
        }

        window.scrollTo({ top: top - offset - headerOffset, behavior: 'smooth' });
    }

    const scrollToElement = (element: HTMLElement, offset: number | undefined = 15) => {

        const headerCanBeSticky = document.querySelector('.header--potential-sticky');

        if (headerCanBeSticky) {
            offset += headerCanBeSticky.offsetHeight;
        }

        const top = element.offsetTop - offset;

        window.scrollTo({ top: top, behavior: 'smooth' });
    }

    const bendWord = (word: String, count: number) => {
        let bentWord = word;

        if (word === 'minuta') {
            if (count > 4) {
                bentWord = 'minut';
            }
            else if (count > 1) {
                bentWord = 'minuty';
            }
        }
        else if (word === 'den') {
            if (count > 4) {
                bentWord = 'dní';
            }
            else if (count > 1) {
                bentWord = 'dny';
            }
        }

        const result = `${count} ${bentWord}`;

        return result;
    }

    const extractDate = (dateTime: Date): Date => {

        const result = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());

        return result;
    }

    const addDaysToDate = (date: Date | string, days: number): Date => {
        const dateInstance = typeof date === 'string' ? new Date(date) : date;
        const result = new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate() + days);
        return result;
    }

    const formatDate = (date: Date | null, disableWordReplacement: Boolean | undefined) => {

        let result;

        if (!date) {
            result = 'na dotaz';
        }

        if (!disableWordReplacement && extractDate(date).getTime() === extractDate(new Date()).getTime()) {
            result = 'dnes';
        }
        else if (!disableWordReplacement && extractDate(date).getTime() === addDaysToDate(new Date(), 1).getTime()) {
            result = 'zítra';
        }
        else if (!disableWordReplacement && extractDate(date).getTime() === addDaysToDate(new Date(), 2).getTime()) {
            result = 'pozítří';
        }
        else {
            result = date.toLocaleDateString(useLocale().getLocale().locale);
        }

        return result;
    }

    const getDayOfWeekName = (date: Date) => {
        const days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'];
        const result = days[date.getDay()];
        return result;
    }

    const camelize = (string: String) => {
        const result = string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
        return result;
    }

    const logError = (message: String) => {
        console.log('\u001b[' + 31 + 'm' + message + '\u001b[0m');
    }

    const logInfo = (message: String) => {
        console.log('\u001b[' + 32 + 'm' + message + '\u001b[0m');
    }

    const removeDiacritics = (text: String) => {
        const result = text?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return result;
    }

    const cleanHtmlAttribute = (text) => {
        const result = text?.replaceAll('"', '&quot;');
        return result;
    };

    return {
        isDeepEqual,
        getGroupPageNumber,
        gtmDataLayerPush,
        slugify,
        getWareImageUrl,
        capitalize,
        scrollTo,
        scrollToElement,
        bendWord,
        extractDate,
        addDaysToDate,
        formatDate,
        getDayOfWeekName,
        camelize,
        removeDuplicatesFromArray,
        logError,
        logInfo,
        removeDiacritics,
        cleanHtmlAttribute
    }
}