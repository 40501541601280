import { config } from "process";
import { useDialog } from "./useDialog";
import { useUtils } from "./useUtils";

export const useCart = () => {

    const appConfig = useAppConfig();
    const analytics = useBaseAnalytics();

    const items = useState('cartItems', () => []);
    const presentation = useState('cartPresentation', () => []);
    const dispatchDate = useState('cartDispatchDate');

    const getEmptyTotalPrices = () => {
        return {
            total: {
                value: 0,
                formattedValue: 0,
            },
            itemsTotal: {
                value: 0,
                valueWithoutVat: 0,
                formattedValue: 0,
            },
            couponSale: {
                value: 0,
            },
            itemsCouponSale: {
                value: 0,
            },
            shipping: {
                value: 0,
                formattedValue: 0,
            },
            payment: {
                value: 0,
                formattedValue: 0,
            },
            freeShipping: {
                required: null,
                remaining: null
            }
        };
    };

    const prices = useState('cartPrices', () => getEmptyTotalPrices());

    const totalQuantity = computed(() => items.value.reduce((sum, current) => sum + current.q, 0));
    const isEmpty = computed(() => items.value.length === 0);

    const getInputCartItems = () => {

        let inputCartItems = [];

        // add all parent items
        inputCartItems = inputCartItems.concat(items.value.map(item => {
            return {
                id: item.cId,
                idParent: null,
                idVariant: item.vId,
                isVirtual: !!(item.set && item.set.length),
                count: item.q,
                countForCustomPrice: item.rq,
                services: item.s?.map(service => {
                    return {
                        idWareServiceOption: service.soId,
                        configuration: service.c,
                        name: service.n,
                        text: service.t
                    }
                })
            };
        }));

        // add all child items in sets
        inputCartItems = inputCartItems.concat(items.value.filter(parentItem => parentItem.set?.length).flatMap(parentItem =>
            parentItem.set?.map(childItem => {
                return {
                    id: `${parentItem.cId}:${childItem.vId}`,
                    idParent: parentItem.cId,
                    idVariant: childItem.vId,
                    count: parentItem.q,
                    countForCustomPrice: parentItem.rq,
                    services: childItem.s ? [{
                        idWareServiceOption: childItem.s.soId,
                        configuration: childItem.s.c,
                        name: childItem.s.f
                    }] : null
                };
            })
        ));

        return inputCartItems;
    }

    // fetches additional data from API
    const getPresentation = async (shippingLevelId, paymentId) => {

        const result = {
            validItems: [],
            invalidItems: []
        };

        if (items.value.length === 0) {
            prices.value = getEmptyTotalPrices();
            return result;
        }

        const locale = useLocale().getLocale();
        const coupon = useCookie('coupon').value?.code;
        const inputCartItems = getInputCartItems();
        const availability = useAvailability();
        const business = useBusiness();

        const selectedCountryCookie = useCookie('selectedCountryId');
        const selectedShippingCookie = useCookie('selectedShippingData');
        const selectedPaymentCookie = useCookie('selectedPaymentData');

        let countryId = selectedCountryCookie.value;
        const finalShippingLevelId = shippingLevelId ?? selectedShippingCookie.value?.shippingLevelId;
        const finalPaymentId = typeof paymentId === 'undefined' ? selectedPaymentCookie.value?.id : paymentId;

        const endpointUrl = `/api/cartList?coupon=${encodeURIComponent(coupon ?? '')}&countryId=${countryId ?? ''}&shippingLevelId=${finalShippingLevelId ?? ''}&paymentId=${finalPaymentId ?? ''}&currencyId=${locale.currencyId}&languageId=${locale.languageId}`;

        const data = (await useApiFetch(endpointUrl, {
            method: 'POST',
            body: {
                items: inputCartItems
            }
        })).cartList;

        //#region update prices

        prices.value.total.value = data.orderPrice.total;
        prices.value.total.formattedValue = data.orderPrice.totalFormatted;

        prices.value.itemsTotal.value = data.orderPrice.items;
        prices.value.itemsTotal.valueWithoutVat = data.orderPrice.itemsWithoutVat;
        prices.value.itemsTotal.formattedValue = data.orderPrice.itemsFormatted;

        prices.value.couponSale.value = -(data.orderPrice.absoluteCouponSale ? data.orderPrice.absoluteCouponSale : data.orderPrice.relativeCouponSale);
        prices.value.itemsCouponSale.value = -(data.orderPrice.absoluteItemsCouponSale ? data.orderPrice.absoluteItemsCouponSale : data.orderPrice.relativeCouponSale);

        prices.value.shipping.value = data.orderPrice.shipping;
        prices.value.shipping.formattedValue = data.orderPrice.shippingFormatted;

        prices.value.payment.value = data.orderPrice.payment;
        prices.value.payment.formattedValue = data.orderPrice.paymentFormatted;

        prices.value.freeShipping.required = data.freeShipping?.requiredAmount;
        prices.value.freeShipping.remaining = data.freeShipping?.remainingAmount;

        //#endregion

        //#region validate

        inputCartItems.forEach(inputCartItem => {
            const presentationCartItem = data.items.find(q => q.id === inputCartItem.id);

            if (presentationCartItem?.variant?.ware?.page) {

                // if the quantity has been changed by the API, update the cart item and put it to the invalid items list for further reference

                if (presentationCartItem.count !== inputCartItem.count) {
                    result.invalidItems.push({
                        name: presentationCartItem.variant.ware.name,
                        code: presentationCartItem.variant.catalogCode?.[0]?.code,
                        variantName: presentationCartItem.variant.variantName,
                        variantId: inputCartItem.idVariant,
                        imageId: presentationCartItem.variant.variantGroups?.[0]?.wareImage?.id ?? presentationCartItem.variant.ware.image?.id,
                        url: presentationCartItem.variant.ware.page.url,
                        originalCount: inputCartItem.count,
                        newCount: presentationCartItem.count
                    });

                    update(inputCartItem.id, presentationCartItem.count, true, null, true);
                }
            }

            // if the item is not in the response, remove it from the cart and put it to the invalid items list for further reference

            else {

                result.invalidItems.push({
                    variantId: inputCartItem.idVariant,
                    originalCount: inputCartItem.count,
                    newCount: 0
                });

                remove(inputCartItem.id, true);

            }
        });

        //#endregion

        // reset max dispatch date
        dispatchDate.value = new Date();

        // append fetched data to cartItems (just top level - subitems with services are included in total prices)
        const itemsData = data.items;

        result.validItems = items.value.map(cartItem => {

            const itemData = itemsData.find(q => q.id === cartItem.cId);
            const itemVariantData = itemData.variant;
            const itemWareData = itemVariantData.ware;
            /*
                        const itemDispatchDate = getDispatchDateForCartItem(itemData, itemsData);
            
                        if (itemDispatchDate.getTime() > dispatchDate.value.getTime()) {
                            dispatchDate.value = itemDispatchDate;
                        }
            */

            const isElectronicDistribution = business.isWareElectronicDistribution(itemWareData);
            const isPersonalPickupOnly = business.isWarePersonalPickupOnly(itemWareData.properties);
            const override30days = business.isWareOverride30days(itemWareData);

            const availabilityLabel = availability.getVariantLabel(
                isElectronicDistribution,
                isPersonalPickupOnly,
                itemVariantData.stored,
                itemVariantData.storedOnOwnStore,
                itemVariantData.preorder,
                override30days);

            return {
                cartItemId: cartItem.cId,
                variantId: cartItem.vId,
                wareId: itemWareData.id,
                quantity: itemData.count,
                relativeQuantity: itemData.countForCustomPrice,
                name: itemWareData.name,
                code: itemData.variant.catalogCode?.[0]?.code,
                variantName: itemVariantData.variantName,
                imageId: itemData.variant.variantGroups?.[0]?.wareImage?.id ?? itemWareData.image?.id,
                url: itemWareData.page.url,
                unit: itemWareData.unit,
                piecePrice: itemData.itemWithSubitemsPrice.unitPrice,
                piecePriceWithoutVat: itemData.itemWithSubitemsPrice.unitPriceWithoutVat,
                piecePriceFormatted: itemData.itemWithSubitemsPrice.formattedUnitPrice,
                totalPriceFormatted: itemData.itemWithSubitemsPrice.formattedTotalPrice,
                totalPrice: itemData.itemWithSubitemsPrice.totalPriceWithoutServices,
                customCountPriceFormatted: itemData.itemWithSubitemsPrice.formattedCustomCountPrice,
                customCountPrice: itemData.itemWithSubitemsPrice.customCountPrice,
                totalBasePrice: itemData.itemWithSubitemsPrice.totalBasePrice,
                sale: itemData.itemWithSubitemsPrice.sale,
                availabilityLabel: availabilityLabel,
                personalPickupOnly: isPersonalPickupOnly,
                stored: itemVariantData.stored,
                storedOnOwnStore: itemVariantData.storedOnOwnStore,
                deliveryPeriod: itemVariantData.deliveryPeriod,
                override30days: override30days,
                preorder: itemVariantData.preorder,
                availableServices: itemWareData.services,
                orderedServices: itemData.services,
            };
        });

        return result;
    }

    const getItemsForAnalytics = () => {

        const result = {
            wareIds: presentation.value.map(q => q.wareId),
            variantIds: presentation.value.map(q => q.variantId)
        }

        return result;
    }

    // fetches and updates additional data to the state
    const updatePresentation = async () => {
        const fullPresentation = await getPresentation();

        presentation.value = fullPresentation.validItems;

        if (fullPresentation.invalidItems.length) {
            useDialog().open('cartItemsRemoved', fullPresentation.invalidItems);
        }

        return fullPresentation;
    }

    const add = async (variantId: Number, quantity: Number, analyticsWare: Object, analyticsVariant: Object) => {

        items.value = getItems();

        let item = items.value.find(q => q.vId === variantId);

        if (typeof item !== 'undefined') {
            item.q += quantity;
            item.rq = quantity;
        }
        else {
            item = {
                cId: getIdForNewCartItem(),
                vId: variantId,
                q: quantity,
                rq: quantity
            };

            items.value.push(item);
        }

        saveCart();

        const presentation = await updatePresentation()
        const presentationItem = presentation.validItems.find(q => q.cartItemId === item.cId);

        const realAddedQuantity = presentationItem.relativeQuantity;
        let overcount = false;

        // if the quantity is not the same as requested, update the cart item
        if (realAddedQuantity != quantity) {
            item.q = presentationItem.quantity;
            overcount = true;
        }

        // if the item couldn´t be added to cart, remove it from cart
        if (!item.q) {
            items.value.splice(items.value.indexOf(item), 1);
        }
        // there are some pieces of the given item in the cart
        else {

            // but no pieces were added > show overcount dialog
            if (realAddedQuantity === 0) {
                useDialog().open('cartOvercount', { quantityInCart: item.q });
            }
        }

        saveCart();

        analyticsItemEvent('add_to_cart', item.cId, quantity);

        return { cartItem: presentationItem, addedQuantity: realAddedQuantity, overcount: overcount };
    };

    const mergeWithLoggedCustomersCart = async () => {

        const locale = useLocale().getLocale();

        const customerCartItems = (await useApiFetch(`/api/customer/cart?currencyId=${locale.currencyId}&languageId=${locale.languageId}`)).customer.savedCart;

        const isCurrentCartLeftAsLogged = window.localStorage.getItem('cartLogged') === 'true';

        // if the current cart has been filled as a logged user, clear it - we don´t want to merge it with the logged user´s cart to avoid duplicates
        if (isCurrentCartLeftAsLogged) {
            items.value = [];
        }

        if (customerCartItems?.length) {
            customerCartItems.forEach(customerCartItem => {

                let item = items.value.find(q => !isCurrentCartLeftAsLogged && q.vId === customerCartItem.idVariant);

                if (typeof item !== 'undefined') {
                    item.q += customerCartItem.count;
                }
                else {
                    item = {
                        cId: getIdForNewCartItem(),
                        vId: customerCartItem.idVariant,
                        q: customerCartItem.count
                    };

                    items.value.push(item);
                }
            });

        }

        saveCart();

        await updatePresentation();
    };

    const update = async (cartItemId: Number, quantity: Number, absolute: Boolean = true, addedRelativeQuantity: Number | null = null, disableFetchingPresentation: Boolean = false) => {

        let item = items.value.find(q => q.cId === cartItemId);

        if (typeof item !== 'undefined') {

            const originalQuantity = item.q;

            if (absolute) {
                item.q = quantity;
            }
            else {
                item.q += quantity;
                item.rq = addedRelativeQuantity;
            }

            if (item.q < 1) {

                analyticsItemEvent('remove_from_cart', item.cId, -originalQuantity);

                const indexOfItem = items.value.indexOf(item);
                items.value.splice(indexOfItem, 1);
            }

            // if the quantity has been descreased with the update
            if (item.q < originalQuantity) {

                // if any items are remaining, call the analytics event - otherwise the event has already been called in the removal block above
                if (item.q) {
                    analyticsItemEvent('remove_from_cart', item.cId, item.q - originalQuantity);
                }

                // truncate the services to the maximum available quantity
                if (item.s) {

                    const serviceIds = useUtils().removeDuplicatesFromArray(item.s.map(service => service.sId));

                    serviceIds.forEach(serviceId => {
                        const servicesOfThisType = item.s.filter(service => service.sId === serviceId);

                        for (let i = 0; i < servicesOfThisType.length; i++) {
                            if (i > item.q - 1) {
                                item.s.splice(item.s.indexOf(servicesOfThisType[i]), 1);
                            }
                        }
                    });
                }
            }
            else {
                analyticsItemEvent('add_to_cart', item.cId, item.q - originalQuantity);
            }

            saveCart();

            if (!disableFetchingPresentation) {
                // check if overcount occured, if so, then truncate the quantity to the maximum available and return the new quantity to show info

                const invalidItems = (await updatePresentation()).invalidItems;

                if (invalidItems.some(q => q.presentationCartItem.id === item.cId)) {
                    return false;
                }
            }
        }

        return true;
    };

    const remove = async (cartItemId: Number, disableFetchingPresentation: Boolean | undefined) => {
        update(cartItemId, 0, true, null, disableFetchingPresentation);
    };

    const clear = async () => {
        items.value = [];
        saveCart();

        await updatePresentation();
    }

    const getItems = () => {
        const cartContents = window.localStorage.getItem('cart');

        const result = JSON.parse(cartContents) || [];

        return result;
    }

    const getIdForNewCartItem = () => {
        const currentMaxId = items.value.length ? Math.max(...items.value.map(item => parseInt(item.cId))) : 0;
        const result = currentMaxId + 1;
        return result.toString();
    }

    const openServiceDialogForCartItem = (presentationCartItem, source) => {
        if (presentationCartItem.availableServices?.length) {

            useWareAnalytics().getItems(
                [presentationCartItem.wareId]
            ).then((data) => {

                if (data?.length) {

                    useDialog().open('engraving', {
                        wareName: presentationCartItem.name,
                        wareImageId: presentationCartItem.imageId,
                        wareCategory: data?.[0]?.item_category,
                        serviceName: presentationCartItem.availableServices[0].name,
                        serviceOptions: presentationCartItem.availableServices[0].options,
                        cartItemData: presentationCartItem,
                        source: source
                    });
                }
            });
        }
    }

    const addServiceToCartItem = async (cartItemId, serviceId, serviceOptionId, presentationText, configuration) => {
        const item = items.value.find(q => q.cId === cartItemId);

        item.s = item.s || [];

        if (item) {
            item.s.push({
                soId: serviceOptionId,
                sId: serviceId,
                t: presentationText,
                c: configuration
            });
        }

        saveCart();
        await updatePresentation();
    }

    const removeServiceFromCartItem = async (cartItemId, serviceOptionId, configuration) => {
        const cartItem = items.value.find(q => q.cId === cartItemId);

        if (cartItem) {

            const presentationCartItem = presentation.value.find(q => q.cartItemId === cartItem.cId);

            const service = cartItem.s.find(q => q.soId === serviceOptionId && q.c === configuration);

            if (service) {
                const indexOfService = cartItem.s.indexOf(service);
                cartItem.s.splice(indexOfService, 1);

                if (presentationCartItem) {
                    useWareAnalytics().getItems(
                        [presentationCartItem.wareId]
                    ).then((data) => {

                        if (data?.length) {

                            analytics.pushEvent('user_interaction', {
                                interaction_name: 'engraving_removed',
                                engraving_option: presentationCartItem.orderedServices.find(service => service.serviceOption.id === serviceOptionId)?.serviceOption.name,
                                engraving_item_name: presentationCartItem.name,
                                engraving_item_category: data?.[0]?.item_category
                            });
                        }
                    });
                }
            }
        }

        saveCart();
        await updatePresentation();
    }

    const analyticsItemEvent = (event, cartItemId, quantity) => {

        const cartItem = presentation.value.find(q => q.cartItemId === cartItemId);

        if (!cartItem) {
            return;
        }

        prepareItemsForAnalyticsEvent().then((data) => {

            if (data?.length) {

                analytics.push({ 'items_cart': data });

                const analyticItem = data.find(q => q.item_variant_id === cartItem.variantId);

                const itemEventObject = Object.assign({ items: [analyticItem], value: quantity * analyticItem.item_variant_price });

                analytics.pushEvent(event, { ecommerce: itemEventObject });
            }
        });
    }

    const prepareItemsForAnalyticsEvent = () => {

        const wareAnalytics = useWareAnalytics();

        const cartItemsForAnalytics = getItemsForAnalytics();

        const result = wareAnalytics.getItems(
            cartItemsForAnalytics.wareIds,
            cartItemsForAnalytics.variantIds,
            presentation.value.map((item) => {
                return {
                    variantId: item.variantId,
                    quantity: item.quantity
                }
            })
        );

        return result;
    }

    const reloadItems = async () => {
        items.value = getItems();
        await updatePresentation();
    }

    const saveCart = async () => {
        if (process.client) {
            window.localStorage.setItem('cart', JSON.stringify(items.value));

            if (useCustomer().isLogged()) {
                await useApiFetch('/api/mutations/saveCart', {
                    method: 'POST',
                    body: {
                        items: getInputCartItems()
                    }
                });

                window.localStorage.setItem('cartLogged', 'true');
            }
            else {
                window.localStorage.removeItem('cartLogged');
            }
        }
    }

    return {
        items,
        presentation,
        totalQuantity: readonly(totalQuantity),
        prices: readonly(prices),
        dispatchDate: readonly(dispatchDate),
        isEmpty: readonly(isEmpty),
        add,
        update,
        remove,
        clear,
        getItems,
        prepareItemsForAnalyticsEvent,
        getPresentation,
        reloadItems,
        getInputCartItems,
        updatePresentation,
        openServiceDialogForCartItem,
        addServiceToCartItem,
        removeServiceFromCartItem,
        mergeWithLoggedCustomersCart
    }
}