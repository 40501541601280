export default defineAppConfig({
  shopZones: [
    {
      domain: 'www.rigad.cz',
      devDomain: 'testing.rigad.cz',
      staticResourcesRoot: 'https://www.rigad.cz',
      shopZoneId: 9,
      language: 'cz',
      countryIds: [
        1,
        2
      ],
      currency: 'CZK',
      locale: 'cs',
      relatedShopZoneIds: [11, 13],
      gtmContainerKey: 'GTM-T4HBKTDF',
      titleSuffix: ' | Rigad.cz',
      allowedFeatures: [
        'PAYMENT_INSTRUCTIONS_QR_PAYMENT_TIP',
        'SEZNAM_WMT',
        'VIVNETWORKS_CONVERSION'
      ],
      disabledFeatures: [],
      vivnetworks: {
        enterpriseId: 1558178,
        actionId: 464452
      },
      robotsIndexable: true,
      heurekaConversionUrl: 'https://im9.cz/js/ext/1-roi-async.js',
      heurekaConversionCode: '73A5389F2565ED10B48605B4BB220075'
    },
    {
      domain: 'www.rigad.sk',
      devDomain: 'www.rigad.sk',
      staticResourcesRoot: 'https://www.rigad.sk',
      shopZoneId: 11,
      language: 'sk',
      countryIds: [
        2,
        1
      ],
      currency: 'EUR',
      locale: 'sk',
      relatedShopZoneIds: [9, 13],
      gtmContainerKey: 'GTM-K6Z386H6',
      titleSuffix: ' | Rigad.sk',
      allowedFeatures: [
        'SEZNAM_WMT',
        'VIVNETWORKS_CONVERSION'
      ],
      disabledFeatures: [],
      vivnetworks: {
        enterpriseId: 1558178,
        actionId: 464453
      },
      robotsIndexable: true,
      heurekaConversionUrl: 'https://im9.cz/sk/js/ext/2-roi-async.js',
      heurekaConversionCode: '1E50D7AF567A1089322B35E5BE22F5D3'
    },
    {
      domain: 'www.rigad.eu',
      devDomain: 'www.rigad.eu',
      staticResourcesRoot: 'https://www.rigad.eu',
      shopZoneId: 10,
      language: 'cz',
      countryIds: [
        1,
        2
      ],
      currency: 'CZK',
      locale: 'cs',
      relatedShopZoneIds: [12, 13],
      gtmContainerKey: 'GTM-KHV52GZR',
      titleSuffix: ' | Rigad.eu',
      allowedFeatures: [
        'PAYMENT_INSTRUCTIONS_QR_PAYMENT_TIP'
      ],
      disabledFeatures: [
        'SERVICES_LINK_IN_HEADER'
      ],
    },
    {
      domain: 'sk.rigad.eu',
      devDomain: 'sk.rigad.eu',
      staticResourcesRoot: 'https://sk.rigad.eu',
      shopZoneId: 12,
      language: 'sk',
      countryIds: [
        2,
        1
      ],
      currency: 'EUR',
      locale: 'sk',
      relatedShopZoneIds: [10, 13],
      gtmContainerKey: 'GTM-KKHFR8N9',
      titleSuffix: ' | Rigad.eu',
      allowedFeatures: [],
      disabledFeatures: [],
    },
    {
      domain: 'www.rigad.com',
      devDomain: 'www.rigad.com',
      staticResourcesRoot: 'https://www.rigad.com',
      shopZoneId: 13,
      language: 'en',
      countryIds: null,
      currency: 'EUR',
      locale: 'en',
      relatedShopZoneIds: [9, 11],
      gtmContainerKey: 'GTM-NL32ZPTJ',
      titleSuffix: ' | Rigad.com',
      allCurrencies: ['EUR', 'USD'],
      allowedFeatures: [
        'SHIPPING_TABLE_DELIVERY_SPEED',
        'CURRENCY_SWITCH',
        'SEZNAM_WMT',
        'LIMITED_SHIPPING_INFO'
      ],
      disabledFeatures: [
        'ORDER_REVIEW_CHECKBOX',
        'WARE_AVAILABILITY_ON_STORES_DIALOG',
        'SHIPPING_TABLE_COD_PRICE',
        'SERVICES_LINK_IN_HEADER',
        'DELIVERY_OPTIONS_IN_WARE'
      ],
      robotsIndexable: true,
    },
    {
      domain: 'www.vyzbrojse.cz',
      staticResourcesRoot: 'https://www.vyzbrojse.cz',
      shopZoneId: 7,
      language: 'cz',
      countryIds: [
        1
      ],
      currency: 'CZK',
      locale: 'cs',
      relatedShopZoneIds: [],
      gtmContainerKey: 'GTM-MZLCQVP',
      titleSuffix: ' | VyzbrojSe',
      allowedFeatures: [
        'SEZNAM_WMT'
      ],
      disabledFeatures: [],
    },
  ],
  defaultShopZone: 'www.rigad.cz',
  currencies: {
    CZK: {
      id: 1,
      sign: 'CZK',
      signLocal: 'Kč',
      minDigits: 0,
      maxDigits: 0,
      accountNumber: '2900107347/2010',
      signBefore: false,
      locale: 'cs',
      decimalSeparator: ','
    },
    EUR: {
      id: 2,
      sign: 'EUR',
      signLocal: '€',
      minDigits: 0,
      maxDigits: 1,
      accountNumber: 'IBAN: CZ172 0100 000 0021 0017 4234, BIC/SWIFT: FIOBCZPPXXX',
      signBefore: true,
      locale: 'en',
      decimalSeparator: ','
    },
    USD: {
      id: 4,
      sign: 'USD',
      signLocal: '$',
      minDigits: 0,
      maxDigits: 1,
      signBefore: true,
      locale: 'en',
      decimalSeparator: '.'
    }
  },
  countries: {
    CZ: 1,
    SK: 2
  },
  languages: {
    cz: 1,
    sk: 2,
    en: 3
  },
  graphQL: {
    endpointUrl: 'http://10.10.10.144:88/graphql'
  },
  moduleMapping: {
    1: "default",
    2: "group",
    41: "themeGroup",
    3: "ware",
    6: "infoPage",
    27: "search",
    39: "login",
    38: "register",
    23: "magazine",
    21: "account-details",
    24: "account-orders",
    29: "account-rewards",
    40: "account-settings",
    31: "order-cart",
    32: "order-shippingPayment",
    33: "order-addresses",
    37: "order-detail",
    35: "emptyCart",
    16: "thankyou",
    22: "lexicon",
    43: "inspiration",
    44: "branches",
    45: "themeGroups",
    46: "optinConfirmation"
  },
  moduleIdsToAwaitCartLoaded: [31, 32, 33],
  moduleIdsWithBlockedIndexing: [21, 24, 29, 40, 31, 32, 33, 37, 16],
  cache: {
    pageIds: [
      26558, // informační centrum
      3453, // kontakty
      18438, // magazín
      20894, // prodejna olomouc
      26552, // prodejna semily
      36225, // prodejna ostrava
      3449, // slevy a výhody
      28805, // stav objednávky
      26553, // doprava
      26551, // platba
      4484, // výměna zboží
      4481, // reklamace zboží
      3451, // obchodní podmínky
      4480, // o nás
      18437, // slovník pojmů
      16648, // velkoobchod
      3455, // ochrana osobních údajů
      21913, // cookies
      33210, // služby a servis
      28750, // značky
      21913, // cookies
      34716, // jetboil akce (textová badge)
      33894, // řazení produktů
      24352, // rss
      32107, // nsn
      33262, // gravírovaní
      27362, // vrácení zboží
      32997, // hrdy dodavatel NATO
      26556, // jak uplatnit slevový kód
    ],
    moduleIds: [10, 11, 13, 16, 21, 22, 23, 24, 25, 27, 29, 31, 32, 33, 34, 35, 37, 38, 39, 40, 41, 42, 43, 44, 45],
  },
  primaryNav: {
    parentGroupId: 4787
  },
  groupIdDleDruhu: 4787,
  groupIdDleVyrobcu: 1090,
  wareList: {
    itemsOnPage: 24,
    searchItems: 12
  },
  magazineList: {
    itemsOnPage: 9,
    searchItems: 6
  },
  orderList: {
    itemsOnPage: 20
  },
  magazineCategories: {
    reviews: 2
  },
  parameterIds: {
    size: 239,
    size1: 240,
    label: 259,
    vendor: 235,
    internal: 278,
    textBadge: 584,
    color: 44,
    sizing: 276,
    warranty: 241,
    magazineLabel: 536,
    materialDetailed: 315,
    material: 52,
    weightDetailed: 297,
    weight: 224
  },
  tagIds: {
    sale: 1,
    clearance: 2,
    news: 3,
    availabilityOnStore: 4
  },
  parameterlValues: {
    label_news: 190621,
    label_sale: 190622,
    label_clearance: 190623,
    label_tip: 190624,
    label_soldato: 216978,
    label_nsn: 388665,
    label_blackFriday: 370310,
    internal_engraving: 413078,
    internal_personalPickupOnly: 325951,
    internal_guide: 220562,
  },
  breakpoints: {
    tablet: 768,
    desktop: 1440
  },
  parameterNameHeadingOverrides: [
    {
      parameterId: 240,
      text: 'Vyberte velikost'
    }
  ],
  homePageGroupId: 981,
  thresholdForNewsBadgeInDays: 60,
  thresholdForNewColorBadgeInDays: 30,
  suppliers: [
    {
      supplierId: 4,
      name: 'E-shop',
      wareListFilterLabel: 'Skladem na eshopu',
      isDefault: true
    },
    {
      supplierId: 8,
      name: 'Semily',
      wareListFilterLabel: 'Skladem na prodejně v Semilech',
      isShop: true,
    },
    {
      supplierId: 13,
      name: 'Olomouc',
      wareListFilterLabel: 'Skladem na prodejně v Olomouci',
      isShop: true,
    },
    {
      supplierId: 17,
      name: 'Ostrava',
      wareListFilterLabel: 'Skladem na prodejně v Ostravě',
      isShop: true,
    },
  ],
  supplierForwardingDelays: [
    // C <> S
    {
      fromId: 4,
      toId: 8,
      days: 1
    },
    // C <> OL
    {
      fromId: 4,
      toId: 13,
      days: 3
    },
    // S <> OL
    {
      fromId: 8,
      toId: 13,
      days: 3
    },
    // C <> OV
    {
      fromId: 4,
      toId: 17,
      days: 3
    },
    // S <> OV
    {
      fromId: 8,
      toId: 17,
      days: 3
    },
    // OL <> OV
    {
      fromId: 13,
      toId: 17,
      days: 3
    },
  ],
  orderDeadlineTime: '1/1/2024 14:00',
  supplierIdsToIncludeInAvailabilityDialog: [4, 8, 13, 17],
  externalLinks: {
    'facebook': 'https://www.facebook.com/rigadgear',
    'instagram': 'https://www.instagram.com/rigad_gear/',
    'youtube': 'https://www.youtube.com/channel/UCl-nA7x6GPSMYgIJCsH8Mnw',
    'zboziCZ': 'https://www.zbozi.cz/obchod/49839/',
    'heurekaCZ': 'https://obchody.heureka.cz/rigad-cz/recenze/',
    'heurekaSK': 'https://obchody.heureka.sk/rigad-sk/recenze/',
  },
  moduleIdsWithDisabledFixedHeader: [
    3, // ware
  ],
  bsWidgetShippingLevelIds: {
    11: 'ZAS', // Zasilkovna CZ
    12: 'ZAS', // Zasilkovna SK
    8: 'CP_BNP', // Balík na poštu
    10: 'CP_BDB', // Balík do balíkovny
    350: 'GLS_PS' // GLS - VM
  },
  newsletterSubscribeSources: {
    registration: 1,
    homePage: 2,
    watchDog: 6
  },
  personalPickupShippingIds: [
    5, // OO Semily
    70, // Prodejna Semily
    8, // OO Olomouc
    6, // Prodejna Olomouc,
    73, // OO Ostrava
    72, // Prodejna Ostrava
    16 // E-mail
  ],
  electronicShippingId: 16,
  shippingProviderIds: {
    base: 1,
    ceskaPosta: 3,
    dhl: 6,
  },
  paymentSystemTypeIds: {
    wire: 1,
    online: 4
  },
  paymentSystemTypeIdsForAdvancePayment: [1, 4, 5],
  paymentIconsBySystemTypeId: {
    1: 'bank',
    2: 'hand-coin',
    3: 'hand-coin',
    4: 'credit-card',
  },
  paymentAdditionalIconsBySystemTypeId: {
    1: 'qr-code'
  },
  orderTagIds: {
    registeredWithOrder: 27,
    trackingScriptsExecuted: 21,
    customerSubscribedToNewsletter: 28,
    reviewRejected: 34
  },
  orderStatusIds: {
    waitingForPayment: 7,
    finalized: 9,
    cancelled: 6,
    readyForPickup: 14,
    dispatched: 5,
    paid: 2
  },
  specialPageCssClasses: {
    28750: 'page-info--vendors',
    26558: 'page-info--information-center',
    20894: 'page-info--branch',
    26552: 'page-info--branch',
    36225: 'page-info--branch',
    3449: 'page-info--loyalty-program',
    33210: 'page-info--services',
  },
  magazineShownCategoriesIds: [
    5, 9, 10, 1, 3, 2, 11
  ],
  infoPageTypes: {
    generic: 1,
    magazine: 2,
    lexicon: 3
  },
  wareServiceIds: {
    engraving: 1
  },
  errorLogging: {
    serviceEndpointUrl: 'https://adminer.drivenet.cz/api/event',
    apiKey: '3E88268DE04E41049BB6B094E21A4689'
  },
  infoPageOverridenIds: [
    3449,
    20894,
    26552,
    26558,
    32135,
    3451,
    4481,
    4484,
    27362,
    33210,
    36225
  ],
  groupIdsToHideInBreadcrumb: [
    4787, // Dle druhu
    1090, // Dle výrobců
    4393, // Služby
  ],
  engravingAllowedFileTypes: [
    'pdf',
    'svg',
    'eps',
    'ai',
    'cdr'
  ],
  propagatedGraphQLErrors: [
    'Registred customer already exists',
    'Invalid Username or Password'
  ],
  organizationMetadataForStructuredData: {
    'cz': {
      'Article/publisher#Organization/name': 'Rigad.cz',
      'Article/publisher#Organization/email': 'podpora@rigad.cz',
      'Article/publisher#Organization/url': 'www.rigad.cz',
      'Article/publisher#Organization/logo#imageObject/url': 'https://www.rigad.cz/img/logo/rigad-black.svg'
    },
    'sk': {
      'Article/publisher#Organization/name': 'Rigad.sk',
      'Article/publisher#Organization/email': 'podpora@rigad.sk',
      'Article/publisher#Organization/url': 'www.rigad.sk',
      'Article/publisher#Organization/logo#imageObject/url': 'https://www.rigad.sk/img/logo/rigad-black.svg'
    },
    'en': {
      'Article/publisher#Organization/name': 'Rigad.com',
      'Article/publisher#Organization/email': 'support@rigad.com',
      'Article/publisher#Organization/url': 'www.rigad.com',
      'Article/publisher#Organization/logo#imageObject/url': 'https://www.rigad.com/img/logo/rigad-black.svg'
    }
  },
  defaultOgImage: '/shopFiles/externi/fb-sdileni-top-armyshop-olomouc.jpg',
  reasonsToShowUnallowedShippings: {
    'MORETHANTWOPACKSOFSHOESINCART': 'Tato doprava neumožňuje zásilku s větším objemem.',
    'ITEMFROMNOTALLOWEDCATEGORYINCART': 'Tato doprava neumožňuje zásilku s vybraným zbožím.',
    'ITEMWITHNOTALLOWEDPARAMETERINCART': 'Tato doprava neumožňuje zásilku s vybraným zbožím.',
    'CARTPRICEOVERALLOWEDSHIPPINGMAXPRICE': 'Pro tuto hodnotu objednávky není možné tuto dopravu využít z důvodu omezení na straně přepravce.',
    'CARTPRICEUNDERALLOWEDSHIPPINGPRICE': 'Pro tuto hodnotu objednávky není možné tuto dopravu využít z důvodu omezení na straně přepravce.',
    'CARTWEIGHTOVERALLOWEDSHIPPINGMAXWEIGHT': 'Pro tuto hmotnost objednávky není možné tuto dopravu využít z důvodu omezení na straně přepravce.',
    'COUPONNOTAPPLICABLE': 'Tuto přepravu nelze vybrat v kombinaci se zadaným kupónem.',
  },
  reasonsToShowUnallowedPayments: {
    'HIDDENPAYMENT': 'Omlouváme se, ale pro vybraný způsob dopravy není tento způsob platby dostupný. Vyberte prosím jinou platební metodu.'
  },
  vivnetworksFilteredIPs: [
    '94.127.133.190', // semily
    '176.102.152.154' // olomouc
  ],
  zboziAggregatorId: 35,
  extendPickupWorkingDays: 5
});